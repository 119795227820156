/* You can add global styles to this file, and also import other style files */


/* Custom Colors */
:root {
    --bs-nav-link-font-size: 24px;
}

/* Forms */
.form-label {
    font-weight: bolder;
}